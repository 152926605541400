import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
var FileSaver = require("file-saver");
const service = new Service();

export const downloadFileBinaryService = {
  downloadFileBinary,
  downloadFile,
};

function downloadFileBinary(id) {
  var param = "id=" + id;
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.DownloadFileBinary, param)
      .then((response) => {
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}

function downloadFile(url, filename) {
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .getBinary(url)
      .then((response) => {
        FileSaver.saveAs(response, filename);
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
