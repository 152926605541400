/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./header.scss";
import "./mobile.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UrlCollect from "../../common/url-collect";
import { getUserService } from "../../redux/store/user/get-user/get-user.action";
import { userService } from "../../redux/store/user/acc-user.action";
import Constants from "../../common/constants";

class HeaderLoginView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {},
    };

    this.logoutClick = this.logoutClick.bind(this);
  }

  componentDidMount() {
    const currentUser = JSON.parse(sessionStorage.getItem("userDetail"));
    if (!currentUser) {
      this.props.getUserDetail().then((x) => {
        if (this.props.getUser.content) {
          sessionStorage.setItem(
            "userDetail",
            JSON.stringify(this.props.getUser.content)
          );
          this.setState({
            currentUser: JSON.parse(sessionStorage.getItem("userDetail")),
          });
        }
      });
    } else {
      this.setState({ currentUser: currentUser });
    }

    // this.getAllPlanningType();
  }

  logoutClick() {
    this.props.logout(this.state);
  }

  render() {
    // const PATHNAME = this.props.match.path;
    // const isLogin = this.state.isLogin;

    const renderInfoAcc = () => {
      if (this.state.currentUser) {
        return (
          <div className="col-md-6">
            <div className="header-nav d-flex align-items-stretch justify-content-end">
              <div className="nav-item administrator">
                <ul className="mg-r-10">
                  <li>
                    <a href="#">
                      <span className="text-header icon-user">
                        <img
                          alt=""
                          src={require("../../assets/icon/carbon_user-avatar-filled.png")}
                        ></img>{" "}
                        {this.state.currentUser.company
                          ? this.state.currentUser.company.name
                          : ""}
                      </span>
                    </a>
                    <div className="sub-menu">
                      <a href={UrlCollect.UserAdmin + Constants.ViewUser}>
                        <img
                          alt=""
                          src={require("../../assets/icon/address-book.png")}
                        ></img>{" "}
                        Thông tin tài khoản
                      </a>
                      <a href={UrlCollect.UserAdmin + Constants.ViewFile0}>
                        <img
                          alt=""
                          src={require("../../assets/icon/b_file-alt.png")}
                        ></img>{" "}
                        Hồ sơ của bạn
                      </a>
                      <a
                        href={
                          UrlCollect.UserAdmin + Constants.ViewChangePassword
                        }
                      >
                        <img
                          alt=""
                          src={require("../../assets/icon/bi_shield-lock-fill.png")}
                        ></img>{" "}
                        Đặt lại mật khẩu
                      </a>
                      <a href="#" onClick={this.logoutClick}>
                        <img
                          alt=""
                          src={require("../../assets/icon/logout-icon 1.png")}
                        ></img>{" "}
                        Đăng xuất
                      </a>
                    </div>
                  </li>
                </ul>
                <a href={UrlCollect.ListNotification} className="notification">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-bell-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    };

    return (
      <header>
        <div className="container header-top-user">
          <div className="row">
            <div className="col-md-6">
              <div className="logo d-flex">
                <a href="/">
                  <img alt="" src={require("../../assets/images/logo.png")} />
                </a>
                <span className="text-header">
                  DỊCH VỤ CÔNG VỀ&nbsp;
                  <br />
                  THẨM DUYỆT THIẾT KẾ, NGHIỆM THU VỀ PHÒNG CHÁY VÀ CHỮA CHÁY
                </span>
              </div>
            </div>
            {renderInfoAcc()}
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  getUser: state.getUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserDetail: getUserService.getUserDetail,
      logout: userService.logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLoginView);
