import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../loading/loading.store";

import Service from "../../../../api/api-service";
import { ApiUrl } from "../../../../api/api-url";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";

const service = new Service();

export const PROJECT_USER_OK = "PROJECT_USER_OK";
export const PROJECT_USER_ERR = "PROJECT_USER_OK";

export function projectUserOk(payload) {
  return {
    type: PROJECT_USER_OK,
    payload,
  };
}

export function projectUserErr(payload) {
  return {
    type: PROJECT_USER_ERR,
    payload,
  };
}

export const projectService = {
  getProject,
};

function getProject(data) {
  var params = "evaluateRecordsTypeId=" + data;
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetLookupProjectUser, params)
      .then((response) => {
        dispatch(projectUserOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(projectUserErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
