import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../../../loading/loading.store";

import Service from "../../../../../api/api-service";
import { ApiUrl } from "../../../../../api/api-url";
import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../../utils/configuration";

const service = new Service();

export const GET_LOOKUP_EVALUATE_RECORDS_GROUP_OK =
  "GET_LOOKUP_EVALUATE_RECORDS_GROUP_OK";
export const GET_LOOKUP_EVALUATE_RECORDS_GROUP_ERR =
  "GET_LOOKUP_EVALUATE_RECORDS_GROUP_ERR";

export function getLookupEvaluateRecordsGroupOk(payload) {
  return {
    type: GET_LOOKUP_EVALUATE_RECORDS_GROUP_OK,
    payload,
  };
}

export function getLookupEvaluateRecordsGroupErr(payload) {
  return {
    type: GET_LOOKUP_EVALUATE_RECORDS_GROUP_ERR,
    payload,
  };
}

export const getLookupEvaluateRecordsGroupService = {
  getLookupEvaluateRecordsGroup,
};

function getLookupEvaluateRecordsGroup(data) {
  var params = "evaluateRecordsTypeId=" + data;
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetLookupEvaluateRecordsGroup, params)
      .then((response) => {
        dispatch(getLookupEvaluateRecordsGroupOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getLookupEvaluateRecordsGroupErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
        ShowNotification(error.errorMessage, NotificationMessageType.Error);
      });
  };
}
