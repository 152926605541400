/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Row from "react-bootstrap/Row";
import { setStateSidebarUser } from "../../../redux/store/user/sidebar/sidebar.action";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { getByIdService } from "../../../redux/store/user/get-by-id/get-by-id.action";
import { downloadFileBinaryService } from "../../../redux/store/user/download-file/download-file-binary.action";
import * as configuration from "../../../utils/configuration";
import Constants from "../../../common/constants";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import SidebarUser from "../../../components/user/sidebar/sidebar-user";
import UrlCollect from "../../../common/url-collect";
import history from "../../../common/history";
import { constants } from "buffer";
var FileSaver = require("file-saver");

const apiRoot = configuration.getConfiguration(
  configuration.ApiServerKey.APP_API_ROOT
);

// const fileId = -1;
const pagination = paginationFactory({
  sizePerPage: 10,
  paginationSize: 5,
  //withFirstAndLast: false,
  alwaysShowAllBtns: true,
  //firstPageText: 'trang dau tien',
  hideSizePerPage: true,
  //showTotal: true,
});

class FileResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.fileId = 21;
    this.fileId = this.props.match.params.id;
  }

  componentDidMount() {
    this.props.getById(this.fileId).then((x) => {
      if (this.props.getByIdState.content) {
      } else {
        history.push(UrlCollect.Page404);
      }
    });
  }

  backClick = (e) => {
    e.preventDefault();
    // this.props.parentCallback(true);
    history.push(UrlCollect.UserAdmin + Constants.ViewFile1000);
  };

  handleDownload(e, fileId) {
    e.stopPropagation();
    // Download file
    this.props.downloadFileBinary(fileId);
  }

  render() {
    const columns = [
      {
        dataField: "file.fileSize",
        headerAttrs: {
          className: "headerFocus",
        },
        text: "STT",
        headerStyle: (colum, colIndex) => {
          return { width: "60px", textAlign: "center" };
        },
        formatter: (cellContent, row, index) => {
          return <span>{index + 1}</span>;
        },
      },
      {
        headerAttrs: {
          className: "headerFocus",
        },
        dataField: "file.fileName",
        text: "Tên tin",
        headerStyle: (colum, colIndex) => {
          return { textAlign: "center" };
        },
      },
      {
        headerAttrs: {
          className: "headerFocus",
        },
        dataField: "file.fileId",
        text: "Tải về",
        headerStyle: (colum, colIndex) => {
          return { width: "60px", textAlign: "center" };
        },
        formatter: (cellContent, row) => {
          return (
            // <a onClick={(e) => this.handleDownload(e, row.fileId)}>
            //   <img src={require("../../../assets/icon/download.png")}></img>
            // </a>
            <a
              className="item-download"
              onClick={() => {
                this.props.downloadFile(
                  apiRoot + row.file.filePreview,
                  row.file.fileName
                );
              }}
            >
              <img
                alt=""
                src={require("../../../assets/icon/download.png")}
              ></img>
            </a>
          );
        },
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // alert(row.id);
      },
    };
    const products = [];
    //Get data file
    if (
      this.props.getByIdState.content &&
      this.props.getByIdState.content.resultFile.length > 0
    ) {
      this.props.getByIdState.content.resultFile.map((item, index) => {
        products.push(item);
      });
    }

    return (
      <div className="wrapper-content">
        <Breadcrumb className="title">
          <Breadcrumb.Item
            href={UrlCollect.HomeUser}
            className="breadcrumb-icon-home"
          >
            <img src={require("../../../assets/icon/entypo_home.png")}></img>{" "}
            Trang chủ
          </Breadcrumb.Item>
          <Breadcrumb.Item href={UrlCollect.UserAdmin + Constants.ViewFile0}>
            Hồ sơ của bạn
          </Breadcrumb.Item>
          <Breadcrumb.Item href={UrlCollect.UserAdmin + Constants.ViewFile1000}>
            Kết quả
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {this.props.getByIdState.content
              ? this.props.getByIdState.content.name
              : ""}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="wrapper">
          <SidebarUser viewDisplay={Constants.ViewFile1000} />
          <section className="file-detail">
            <Container className="p-r-0">
              <div className="body-content-list">
                <Navbar className="catalog" variant="light">
                  <Nav className="mr-auto">
                    <Nav.Link className="label-category">
                      {" "}
                      {this.props.getByIdState.content
                        ? this.props.getByIdState.content.name
                        : ""}
                    </Nav.Link>
                  </Nav>
                </Navbar>
                <section className="content">
                  <Form.Label className="group-info">
                    Hồ sơ thẩm duyệt
                  </Form.Label>
                  <BootstrapTable
                    rowEvents={rowEvents}
                    keyField="id"
                    data={products}
                    columns={columns}
                    pagination={pagination}
                    classes="table-striped"
                    rowClasses="mytable-hover-row wrap-text"
                  />
                </section>
                <section className="p-l-10">
                  <Row>
                    <Col>
                      <Button
                        className="button-register"
                        variant="primary"
                        type="submit"
                        onClick={this.backClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                          />
                        </svg>{" "}
                        QUAY LẠI
                      </Button>
                    </Col>
                  </Row>
                  <Form.Label className="group-info"></Form.Label>
                </section>
              </div>
            </Container>
          </section>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  getByIdState: state.getByIdUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SetStateSidebar: setStateSidebarUser,
      getById: getByIdService.getById,
      downloadFileBinary: downloadFileBinaryService.downloadFileBinary,
      downloadFile: downloadFileBinaryService.downloadFile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FileResult);
