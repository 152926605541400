export const ApiUrl = {
  //--- Account
  Login: "api/Account/Login",
  // Register: "api/Account/Register",
  ForgotPassword: "api/Account/ForgotPassword",
  ResetPassword: "api/Account/ResetPassword",
  ContactToAdmin: "api/cms/HomePage/ContactToAdmin",

  //Home Page
  SlideShow: "api/cms/HomePage/GetListPostHomePage",

  //Planning
  GetListStatement: "api/cms/Statement/GetListStatement",
  GetDetailStatement: "api/cms/Statement/GetDetailStatement",
  SearchPlanning: "api/admin/Planning/search-planning-by",
  PlanningType: "api/cms/Planning/get-all-planning-type",
  ApprovalAgencyLevel:
    "api/admin/ApprovalAgencyLevel/get-approval-agency-level",
  ServiceLink: "api/cms/Statement/GetAllServiceLink",
  SearchStatement: "api/cms/Statement/search-statement-by",
  PlanningTypeById: "api/cms/Planning/get-planning-type-by-id",
  PlanningSearchByIdName: "api/cms/Planning/search-planning-type-by-id-name",
  GetAllPlanningByTypeId: "api/cms/Planning/get-all-planning",
  GetPlanningById: "api/cms/Planning/get-planning-by-id",
  GetMapPlanningById: "api/cms/Planning/get-map-by-planning-id",
  GetPlanningRelationshipByPlanningId:
    "api/cms/Planning/get-planning-relation-by-planning-id",
  GetBoundariesByPlanningId: "api/cms/Planning/get-boundaries-by-planning-id",
  GetYearStatement: "api/cms/Planning/get-all-year-statment",
  GetAllBoundariesOfAllPlanning: "api/cms/Planning/get-all-planning-boundaries",
  GetDetailPlanningById: "api/cms/Planning/get-planning-by-id",

  //--- Map
  GetMapById: "api/admin/Map/get-map-by-id",
  MapFilter: "api/MapTools/MapFilter",
  GetDropDown: "api/MapTools/GetDropDown",
  SpatialSearch: "api/MapTools/SpatialSearch",

  //--- Consultant Community
  GetListConsultantCommunity:
    "api/cms/ConsultantCommunity/get-data-list-consultant-community",
  GetDetailConsultantCommunity:
    "api/cms/ConsultantCommunity/get-detail-consult-community",
  GetDetailFormTemplate: "api/cms/ConsultantCommunity/GetDetailFormTemplate",
  CreateFeedback: "api/cms/ConsultantCommunity/CreateFeedback",
  GetReportFeedback: "api/cms/ConsultantCommunity/GetReportFeedback",
  GetListExpiredForm: "api/cms/ConsultantCommunity/GetListForm",
  GetListResultConsultantCommunity:
    "api/cms/ConsultantCommunity/get-data-list-result-consultant-community",

  //--- News

  GetListNews: "api/cms/News/GetListAll",
  GetListNewsByCategory: "/api​/cms​/News​/GetListByCat",
  GetListNewsByTag: "/api​/cms​/News​/GetListByTag​",
  GetListFeatureNews: "/api​/cms​/News​/GetListFeature​",
  GetListHotNews: "/api/cms/News/GetListHot",
  GetDetailNews: "/api/cms/News/GetDetail",
  SearchNews: "/api/cms/News/Search",

  // Document
  DocumentType: "api/cms/Planning/get-all-document-type",
  SearchDocument: "api/cms/Planning/search-document-by",
  GetCmsLookupDistrict: "api/cms/Administrative/GetLookupDistrict",
  GetDocumentByPlanningId: "api/cms/Planning/get-document-by-planning-id",
  GetChildrenDocument: "api/cms/Planning/get-document-by-planning-id-parent-id",
  DownloadFile: "api/admin/DocumentUpload/DownloadFileBinary",
  GetLookupCommune: "api/admin/Administrative/GetLookupCommune",

  //User
  GetLookupProvince: "api/cms/Administrative/GetLookupProvince",
  GetLookupDistrict: "api/cms/Administrative/GetLookupDistrict",
  GetLookupDistrictWithArrayProvince:
    "api/cms/Administrative/GetLookupDistrictWithArrayProvince",
  Register: "api/AccountUI/Register",
  LoginUser: "api/AccountUI/Login",
  GetLookupProjectUser: "api/cms/Administrative/GetLookupProject",
  GetCompanyByTaxCode: "api/cms/Company/GetCompanyByTaxCode",
  GetUserAccountDetail: "api/AccountUI/GetUserAccountDetail",
  CreateStep1: "api/cms/EvaluateRecords/CreateStep1",
  UpdateStep1: "api/cms/EvaluateRecords/Update",
  EvaluateRecordsType_GetDetail: "api/EvaluateRecordsType/GetDetail",
  EvaluateRecordsType_UploadFile: "api/cms/EvaluateRecords/UploadFile",
  EvaluateRecordsType_FinishCreate: "api/cms/EvaluateRecords/FinishCreate",
  EvaluateRecordsType_DeleteFile: "/api/cms/EvaluateRecords/DeleteFile/",
  EvaluateRecords_Delete: "api/cms/EvaluateRecords/Delete/",
  CMSGetListAll: "api/cms/EvaluateRecords/GetListAll",
  CMSGetById: "api/cms/EvaluateRecords/GetById/",
  CMSGetByCode: "api/cms/EvaluateRecords/GetByCode",
  UpdateUserAccount: "api/AccountUI/UpdateUserAccount",
  ChangePassword: "api/AccountUI/ChangePassword",
  QualifyGetListAll: "api/cms/Qualify/GetListAll",
  QualifyGetDetail: "api/cms/Qualify/GetDetail/",
  HelpingGetListAll: "api/cms/Helping/GetListAll",
  HelpingGetDetail: "api/cms/Helping/GetDetail/",
  DownloadFileBinary: "api/cms/DocumentUpload/DownloadFileBinary",
  NotificationGetListAll: "api/cms/Notification/GetListAll",
  NotificationGetDetail: "api/cms/Notification/GetDetail/",
  CMSForgotPassword: "api/AccountUI/ForgotPassword",
  GetListLinkService: "api/cms/Administrative/GetListLinkService",
  GetLookupEvaluateRecordsGroup:
    "api/cms/Administrative/GetLookupEvaluateRecordsGroup",

  //Admin
  GetLookupProvinceAdm: "api/admin/Administrative/GetLookupProvince",
  GetListDistrictAdm: "api/admin/Administrative/GetListDistrict",
  GetLookupCategoryAdm: "api/admin/News_Category/GetLookupCategory",
  GetDetailAdm: "api/cms/News/GetDetail/",
  LoginAdm: "api/AccountAdmin/Login",
  UpdateCommuneAdm: "api/admin/Administrative/UpdateCommune",
  CreateCommuneAdm: "api/admin/Administrative/CreateCommune",
  DeleteCommuneAdm: "api/admin/Administrative/DeleteCommune",
  GetListCommuneAdm: "api/admin/Administrative/GetListCommune",
  UpdateDepartmentAdm: "api/admin/Department/Update",
  CreateDepartmentAdm: "api/admin/Department/Create",
  DeleteDepartmentAdm: "api/admin/Department/Delete/",
  GetListAllDepartmentAdm: "api/admin/Department/GetListAll",
  UpdateDistrictAdm: "api/admin/Administrative/UpdateDistrict",
  CreateDistrictAdm: "api/admin/Administrative/CreateDistrict",
  DeleteDistrictAdm: "api/admin/Administrative/DeleteDistrict",
  UpdateNewsAdm: "api/admin/News/Update",
  CreateNewsAdm: "api/admin/News/Create",
  DeleteNewsAdm: "api/admin/News/Delete/",
  UpdatePositionAdm: "api/admin/Position/Update",
  CreatePositionAdm: "api/admin/Position/Create",
  DeletePositionAdm: "api/admin/Position/Delete/",
  GetListAllPositionAdm: "api/admin/Position/GetListAll",
  UpdateProjectAdm: "api/admin/Project/Update",
  CreateProjectAdm: "api/admin/Project/Create",
  DeleteProjectAdm: "api/admin/Project/Delete/",
  GetListAllProjectAdm: "api/admin/Project/GetListAll",
  UpdateProvinceAdm: "api/admin/Administrative/UpdateProvince",
  CreateProvinceAdm: "api/admin/Administrative/CreateProvince",
  DeleteProvinceAdm: "api/admin/Administrative/DeleteProvince",
  GetListProvinceAdm: "api/admin/Administrative/GetListProvince",
  UpdateLinkServiceAdm: "api/admin/Administrative/UpdateLinkService",
  CreateLinkServiceAdm: "api/admin/Administrative/CreateLinkService",
  DeleteLinkServiceAdm: "api/admin/Administrative/DeleteLinkService",
  GetListLinkServiceAdm: "api/admin/Administrative/GetListLinkService",
  UploadNewsImageAdm: "api/cms/DocumentUpload/UploadNewsImage",
};
