import {
  AddOneSlotToLoadingQueue,
  RemoveOneSlotToLoadingQueue,
} from "../loading/loading.store";
//import history from "../../../../common/history";
//import UrlCollect from "../../../../common/url-collect";
import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
const service = new Service();

export const GET_LOOKUP_DISTRICT_WITH_ARRAY_PROVINCE_OK =
  "GET_LOOKUP_DISTRICT_WITH_ARRAY_PROVINCE_OK";
export const GET_LOOKUP_DISTRICT_WITH_ARRAY_PROVINCE_ERR =
  "GET_LOOKUP_DISTRICT_WITH_ARRAY_PROVINCE_ERR";

export function getLookupDistrictWithArrayProvinceOk(payload) {
  return { type: GET_LOOKUP_DISTRICT_WITH_ARRAY_PROVINCE_OK, payload };
}

export function getLookupDistrictWithArrayProvinceErr(payload) {
  return { type: GET_LOOKUP_DISTRICT_WITH_ARRAY_PROVINCE_ERR, payload };
}

export const districtWithArrayProvinceService = {
  getLookupDistrictWithArrayProvince,
};

function getLookupDistrictWithArrayProvince(provinceIds) {
  var params = "";
  for (var i = 0; i < provinceIds.length; i++) {
    if (params == "") {
      params += "provinceIds=" + provinceIds[i].id;
    } else {
      params += "&provinceIds=" + provinceIds[i].id;
    }
  }
  return function (dispatch) {
    dispatch(AddOneSlotToLoadingQueue);
    return service
      .get(ApiUrl.GetLookupDistrictWithArrayProvince, params)
      .then((response) => {
        dispatch(getLookupDistrictWithArrayProvinceOk(response));
        dispatch(RemoveOneSlotToLoadingQueue);
      })
      .catch((error) => {
        dispatch(getLookupDistrictWithArrayProvinceErr(error));
        dispatch(RemoveOneSlotToLoadingQueue);
      });
  };
}
