import {
  GET_LOOKUP_EVALUATE_RECORDS_GROUP_OK,
  GET_LOOKUP_EVALUATE_RECORDS_GROUP_ERR,
} from "./get-lookup-evaluate-records-group.action";

const InitState = {
  error: null,
  content: null,
};

export default function GetLookupEvaluateRecordsGroupReducer(
  state = InitState,
  action
) {
  switch (action.type) {
    case GET_LOOKUP_EVALUATE_RECORDS_GROUP_OK: {
      return {
        ...state,
        error: null,
        content: action.payload.content,
      };
    }
    case GET_LOOKUP_EVALUATE_RECORDS_GROUP_ERR: {
      return {
        ...state,
        error: action.payload.err,
        content: null,
      };
    }
    default:
      return state;
  }
}
