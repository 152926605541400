/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "../file-detail/file-detail.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Row from "react-bootstrap/Row";
import UrlCollect from "../../../common/url-collect";
import history from "../../../common/history";
import { setStateSidebarUser } from "../../../redux/store/user/sidebar/sidebar.action";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { getByIdService } from "../../../redux/store/user/get-by-id/get-by-id.action";
import * as configuration from "../../../utils/configuration";
import Constants from "../../../common/constants";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import SidebarUser from "../../../components/user/sidebar/sidebar-user";
import { evaluateRecordDeleteService } from "../../../redux/store/user/evaluateRecords/delete/delete.action";
import { downloadFileBinaryService } from "../../../redux/store/user/download-file/download-file-binary.action";

var FileSaver = require("file-saver");

const apiRoot = configuration.getConfiguration(
  configuration.ApiServerKey.APP_API_ROOT
);
// const fileId = -1;
const pagination = paginationFactory({
  sizePerPage: 10,
  paginationSize: 5,
  //withFirstAndLast: false,
  alwaysShowAllBtns: true,
  //firstPageText: 'trang dau tien',
  hideSizePerPage: true,
  //showTotal: true,
});

class FileDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 0,
      evaluateRecordsTypeId: 0,
    };
    this.fileId = this.props.match.params.id;
    // Check user when search by code in home
    // View initial done set is null
    // if (this.props.getByCodeUser.content) {
    //   this.props.getByCodeUser.content = null;
    // }
    // if (this.props.getByIdRedirectUser.content) {
    //   this.props.getByIdRedirectUser.content = null;
    // }
  }

  componentDidMount() {
    this.props.getById(this.fileId).then((x) => {
      if (this.props.getByIdState.content) {
        this.setState({
          status: this.props.getByIdState.content.status,
          evaluateRecordsTypeId: this.props.getByIdState.content
            .evaluateRecordsTypeId,
        });
      } else {
        history.push(UrlCollect.Page404);
      }
    });
  }

  EditClick = (e) => {
    e.preventDefault();
    history.push(
      UrlCollect.PlanningDesign +
        "/" +
        this.state.evaluateRecordsTypeId +
        "/" +
        this.fileId
    );
  };

  backClick = (e) => {
    e.preventDefault();
    // this.props.parentCallback(true);
    this.back();
  };

  back() {
    let status;
    if (this.state.status === 0) {
      status = Constants.ViewFile0;
    } else if (this.state.status === 2) {
      status = Constants.ViewFile2;
    } else {
      status = Constants.ViewFile1;
    }
    history.push(UrlCollect.UserAdmin + status);
  }

  render() {
    const columns = [
      {
        headerAttrs: {
          className: "headerFocus",
        },
        dataField: "recordsElementId",
        text: "STT",
        headerStyle: (colum, colIndex) => {
          return { width: "40px", textAlign: "center" };
        },
      },
      {
        headerAttrs: {
          className: "headerFocus",
        },
        dataField: "recordElement.name",
        text: "Tên hồ sơ",
        headerStyle: (colum, colIndex) => {
          return { textAlign: "center" };
        },
      },
      {
        headerAttrs: {
          className: "headerFocus",
        },
        dataField: "file.fileName",
        text: "Tập tin",
        headerStyle: (colum, colIndex) => {
          return { textAlign: "center" };
        },
        formatter: (cellContent, row) => {
          return (
            // <a onClick={(e) => this.handleDownload(e, row.fileId)}>
            //   <img src={require("../../../assets/icon/download.png")}></img>
            // </a>
            <a
              className="item-download"
              onClick={() => {
                this.props.downloadFile(
                  apiRoot + row.file.filePreview,
                  row.file.fileName
                );
              }}
            >
              {row.file.fileName}
            </a>
          );
        },
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // alert(row.id);
      },
    };
    const products = [];
    if (
      this.props.getByIdState.content &&
      this.props.getByIdState.content.listFile.length > 0
    ) {
      this.props.getByIdState.content.listFile.map((item, index) => {
        products.push(item);
      });
    }

    let nameStatus;
    let status;
    if (
      this.props.getByIdState.content &&
      this.props.getByIdState.content.status === 0
    ) {
      status = Constants.ViewFile0;
      nameStatus = "Hồ sơ chưa nộp";
    } else if (
      this.props.getByIdState.content &&
      this.props.getByIdState.content.status === 2
    ) {
      status = Constants.ViewFile2;
      nameStatus = "Hồ sơ cần bổ sung";
    } else {
      status = Constants.ViewFile1;
      nameStatus = "Hồ sơ đã nộp";
    }
    return (
      <div className="wrapper-content">
        <Breadcrumb className="title">
          <Breadcrumb.Item
            href={UrlCollect.HomeUser}
            className="breadcrumb-icon-home"
          >
            <img src={require("../../../assets/icon/entypo_home.png")}></img>{" "}
            Trang chủ
          </Breadcrumb.Item>
          <Breadcrumb.Item href={UrlCollect.UserAdmin + Constants.ViewFile0}>
            Hồ sơ của bạn
          </Breadcrumb.Item>
          <Breadcrumb.Item href={UrlCollect.UserAdmin + status}>
            {this.props.getByIdState.content
              ? this.props.getByIdState.content.statusName
              : ""}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {this.props.getByIdState.content
              ? this.props.getByIdState.content.name
              : ""}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="wrapper">
          {this.props.getByIdState.content ? (
            <SidebarUser viewDisplay={status} />
          ) : (
            ""
          )}

          <section className="file-detail">
            <Container className="p-r-0">
              <div className="body-content-list">
                <Navbar className="catalog" variant="light">
                  <Nav className="mr-auto">
                    <Nav.Link className="label-category">
                      {" "}
                      {this.props.getByIdState.content
                        ? this.props.getByIdState.content.name
                        : ""}
                      <span className="status">
                        (
                        {this.props.getByIdState.content
                          ? this.props.getByIdState.content.statusName
                          : ""}
                        )
                      </span>
                    </Nav.Link>
                  </Nav>
                </Navbar>
                <section className="content">
                  <Form.Label className="group-info">Loại hồ sơ</Form.Label>
                  <Row>
                    <Col>
                      <Form.Label>
                        <span className="">
                          {this.props.getByIdState.content
                            ? this.props.getByIdState.content
                                .evaluateRecordsTypeName
                            : ""}
                        </span>
                      </Form.Label>
                    </Col>
                  </Row>

                  <Form.Label className="group-info">
                    Thông tin dự án/công trình/phương tiện
                  </Form.Label>
                  <Row>
                    <Col>
                      <Form.Label>Tên dự án/công trình/phương tiện</Form.Label>
                    </Col>
                    <Col>
                      <Form.Label>
                        {this.props.getByIdState.content &&
                        this.props.getByIdState.content.evaluateRecordsTypeId ==
                          4
                          ? "Danh mục loại phương tiện "
                          : "Danh mục loại dự án "}
                      </Form.Label>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Control
                        value={
                          this.props.getByIdState.content
                            ? this.props.getByIdState.content.name
                            : ""
                        }
                        className="bg-white"
                        readOnly={true}
                        type="text"
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        value={
                          this.props.getByIdState.content
                            ? this.props.getByIdState.content.projectName
                            : ""
                        }
                        className="bg-white"
                        readOnly={true}
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row className="mg-t-10">
                    <Col>
                      <Form.Label>
                        Địa điểm xây dựng/chế tạo/hoán cải
                      </Form.Label>
                    </Col>
                    <Col>
                      <Form.Label>Nhóm</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Control
                        value={
                          this.props.getByIdState.content
                            ? this.props.getByIdState.content.address
                            : ""
                        }
                        className="bg-white"
                        readOnly={true}
                        type="text"
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        className="bg-white"
                        readOnly={true}
                        type="text"
                        value={
                          this.props.getByIdState.content
                            ? this.props.getByIdState.content
                                .evaluateRecordsGroupName
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mg-t-10">
                    <Col>
                      <Form.Label>Tổng mức đầu tư</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Control
                        className="bg-white"
                        readOnly={true}
                        type="text"
                        value={
                          this.props.getByIdState.content
                            ? this.props.getByIdState.content.totalInvestment
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Form.Label className="group-info">
                    Hồ sơ thẩm duyệt
                  </Form.Label>
                  <BootstrapTable
                    rowEvents={rowEvents}
                    keyField="id"
                    data={products}
                    columns={columns}
                    pagination={pagination}
                    classes="table-striped"
                    rowClasses="mytable-hover-row wrap-text"
                  />
                </section>
                <section className="p-l-10">
                  <Row>
                    <Col>
                      <Button
                        className="button-register"
                        variant="primary"
                        type="submit"
                        onClick={this.backClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                          />
                        </svg>{" "}
                        QUAY LẠI
                      </Button>
                    </Col>
                    <Col>
                      {this.state.status === 0 || this.state.status === 2 ? (
                        <span>
                          <Button
                            className="button-register mg-r-10"
                            variant="primary"
                            onClick={this.EditClick}
                          >
                            CHỈNH SỬA
                          </Button>
                        </span>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Form.Label className="group-info"></Form.Label>
                </section>
              </div>
            </Container>
          </section>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  getByIdState: state.getByIdUser,
  getByCodeUser: state.getByCodeUser,
  getByIdRedirectUser: state.getByIdRedirectUser,
  evaluateRecordDeleteState: state.evaluateRecordDelete,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SetStateSidebar: setStateSidebarUser,
      getById: getByIdService.getById,
      evaluateRecordDelete: evaluateRecordDeleteService.evaluateRecordDelete,
      downloadFile: downloadFileBinaryService.downloadFile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FileDetail);
