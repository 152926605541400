/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./home-user.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { getByCodeService } from "../../redux/store/user/get-by-code/get-by-code.action";
import UrlCollect from "../../common/url-collect";
import history from "../../common/history";
import Constants from "../../common/constants";
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";

class HomeUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image1: "",
      image2: "",
      image3: "",
      image4: "",
      image5: "",
      image6: "",
      keyword: "",
    };
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch(e) {
    // e.preventDefault();
    if (this.state.keyword) {
      var param = "code=" + this.state.keyword;
      this.props.getByCode(param).then((x) => {
        if (this.props.getByCodeUser.content) {
          // var status;
          // if (this.props.getByCodeUser.content.status === 1) {
          //   status = Constants.ViewFile1;
          // } else if (this.props.getByCodeUser.content.status === 2) {
          //   status = Constants.ViewFile2;
          // } else {
          //   status = Constants.ViewFile0;
          // }

          history.push(
            UrlCollect.FileDetail + "/" + this.props.getByCodeUser.content.id
          );
        } else {
          ShowNotification(
            "Không tìm thấy mã hồ sơ",
            NotificationMessageType.Warning
          );
        }
      });
    }
  }
  render() {
    return (
      <section className="home-user">
        <div className="banner">
          <div className="container">
            <div className="home-title-1">DỊCH VỤ CÔNG VỀ</div>
            <div className="home-title-2">
              THẨM DUYỆT THIẾT KẾ, NGHIỆM THU VỀ PHÒNG CHÁY VÀ CHỮA CHÁY
            </div>
            <div className="search">
              <div>
                <InputGroup className="input-search-w">
                  <FormControl
                    required
                    placeholder="Nhập mã hồ sơ"
                    aria-label="Nhập mã hồ sơ"
                    aria-describedby="basic-addon2"
                    value={this.state.keyword ? this.state.keyword : ""}
                    onChange={(e) => {
                      this.setState({ keyword: e.target.value });
                    }}
                  />
                  <InputGroup.Append>
                    <Button
                      onClick={this.handleSearch}
                      className="button-search"
                    >
                      TRA CỨU
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="step">
          <Row className="clear-margin">
            <Col className="step-info">
              <div className="center">
                Quy trình <span className="c-yellow">4</span> bước thẩm duyệt{" "}
                <br />
                thiết kế trực tuyến
              </div>
            </Col>
            <Col className="step-info">
              <h2>1</h2>
              <div>
                <span className="c-yellow">CHUẨN BỊ HỒ SƠ</span>
              </div>
            </Col>
            <Col className="step-info">
              <h2>2</h2>
              <div>
                <span className="c-yellow">NỘP HỒ SƠ</span>
              </div>
            </Col>
            <Col className="step-info">
              <h2>3</h2>
              <div>
                <span className="c-yellow">NỘP LỆ PHÍ</span>
              </div>
            </Col>
            <Col className="step-info clear-border">
              <h2>4</h2>
              <div>
                <span className="c-yellow">NHẬN KẾT QUẢ </span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="category">
          <Container className="category-margin">
            <Row>
              {/* <Col>
                <a
                  href="/appraisal-and-approval/1"
                  onMouseEnter={(e) =>
                    this.setState({
                      image1: require("../../assets/icon/w_map 1.png"),
                    })
                  }
                  onMouseLeave={(e) =>
                    this.setState({
                      image1: require("../../assets/icon/w_map 1 (1).png"),
                    })
                  }
                >
                  <Card className="category-item">
                    <Card.Img
                      variant="top"
                      src={
                        this.state.image1
                          ? this.state.image1
                          : require("../../assets/icon/w_map 1 (1).png")
                      }
                    />
                    <Card.Body>
                      <Card.Text>Thẩm duyệt dự án thiết kế quy hoạch</Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col> */}
              <Col>
                <a
                  href="/appraisal-and-approval/1"
                  onMouseEnter={(e) =>
                    this.setState({
                      image2: require("../../assets/icon/w_map 2.png"),
                    })
                  }
                  onMouseLeave={(e) =>
                    this.setState({
                      image2: require("../../assets/icon/w_map 2 (1).png"),
                    })
                  }
                >
                  <Card className="category-item">
                    <Card.Img
                      variant="top"
                      src={
                        this.state.image2
                          ? this.state.image2
                          : require("../../assets/icon/w_map 2 (1).png")
                      }
                    />
                    <Card.Body>
                      <Card.Text>Chấp thuận địa điểm xây dựng</Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col>
                <a
                  href="/appraisal-and-approval/2"
                  onMouseEnter={(e) =>
                    this.setState({
                      image3: require("../../assets/icon/w_blueprint.png"),
                    })
                  }
                  onMouseLeave={(e) =>
                    this.setState({
                      image3: require("../../assets/icon/b_blueprint.png"),
                    })
                  }
                >
                  <Card className="category-item">
                    <Card.Img
                      variant="top"
                      src={
                        this.state.image3
                          ? this.state.image3
                          : require("../../assets/icon/b_blueprint.png")
                      }
                    />
                    <Card.Body>
                      <Card.Text>
                        Góp ý hồ sơ thiết kế cơ sở dự án, công trình
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col>
                <a
                  href="/appraisal-and-approval/3"
                  onMouseEnter={(e) =>
                    this.setState({
                      image5: require("../../assets/icon/w_plan.png"),
                    })
                  }
                  onMouseLeave={(e) =>
                    this.setState({
                      image5: require("../../assets/icon/b_plan.png"),
                    })
                  }
                >
                  <Card className="category-item">
                    <Card.Img
                      variant="top"
                      src={
                        this.state.image5
                          ? this.state.image5
                          : require("../../assets/icon/b_plan.png")
                      }
                    />
                    <Card.Body>
                      <Card.Text>
                        Thẩm duyệt thiết kế kỹ thuật hoặc thiết kế bản vẽ thi
                        công dự án, công trình
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            </Row>
            <Row>
              <Col>
                <a
                  href="/appraisal-and-approval/4"
                  onMouseEnter={(e) =>
                    this.setState({
                      image4: require("../../assets/icon/w_truck.png"),
                    })
                  }
                  onMouseLeave={(e) =>
                    this.setState({
                      image4: require("../../assets/icon/b_truck.png"),
                    })
                  }
                >
                  <Card className="category-item">
                    <Card.Img
                      variant="top"
                      src={
                        this.state.image4
                          ? this.state.image4
                          : require("../../assets/icon/b_truck.png")
                      }
                    />
                    <Card.Body>
                      <Card.Text>
                        Thiết kế kỹ thuật phương tiện giao thông cơ giới có yêu
                        cầu đặc biệt về bảo đảm an toàn PCCC
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col>
                <a
                  href="/appraisal-and-approval/5"
                  onMouseEnter={
                    (e) =>
                      this.setState({
                        image6: require("../../assets/icon/w_office-building.png"),
                      })
                    // setImage6(require("../../assets/icon/w_office-building.png"))
                  }
                  onMouseLeave={
                    (e) =>
                      this.setState({
                        image6: require("../../assets/icon/b_office-building.png"),
                      })
                    // setImage6(require("../../assets/icon/b_office-building.png"))
                  }
                >
                  <Card className="category-item">
                    <Card.Img
                      variant="top"
                      src={
                        this.state.image6
                          ? this.state.image6
                          : require("../../assets/icon/b_office-building.png")
                      }
                    />
                    <Card.Body>
                      <Card.Text>
                        Nghiệm thu về phòng cháy và chữa cháy
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  // sidebarUser: state.sidebarUser,
  getByCodeUser: state.getByCodeUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // SetStateSidebar: setStateSidebarUser,
      getByCode: getByCodeService.getByCode,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HomeUser);

// const mapStateToProps = (state) => ({
//   isLoading: state.app.loading,
//   listPolygon: state.mapData.listPolygon,
// });

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       showLoading: appActions.ShowLoading,
//       getSlideShow: homePageStore.GetSlideShow,
//       getAllPlanningByTypeId: homePageStore.GetAllPlanningByTypeId,
//       GetAllPlanningBoundariesGeomText:
//         MapDataStore.GetAllPlanningBoundariesGeomText,
//     },
//     dispatch
//   );

// export default geolocated({
//   positionOptions: {
//     enableHighAccuracy: false,
//   },
//   userDecisionTimeout: 5000,
// })(connect(mapStateToProps, mapDispatchToProps)(HomeUser));
