/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import "./planning-design.scss";
import { provinceService } from "../../../redux/store/province/province.service";
import { districtService } from "../../../redux/store/district/district.action";
import { communeService } from "../../../redux/store/commune/commune.action";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { projectService } from "../../../redux/store/user/project/project.action";
import { companyService } from "../../../redux/store/user/company/company.action";
import { getUserService } from "../../../redux/store/user/get-user/get-user.action";
import { createStep1Service } from "../../../redux/store/user/createStep1/createStep1.action";
import { evaluateRecordsTypeService } from "../../../redux/store/user/evaluate-records-type/evaluate-records-type.action";
import { uploadFileService } from "../../../redux/store/user/uploadFile/uploadFile.action";
import { finishCreateService } from "../../../redux/store/user/finishCreate/finishCreate.action";
import { deleteFileService } from "../../../redux/store/user/delete-file/delete-file.action";
import { updateStep1Service } from "../../../redux/store/user/updateStep1/updateStep1.action";

import UrlCollect from "../../../common/url-collect";
import DateFormat from "dateformat";
import history from "../../../common/history";
import { getByIdService } from "../../../redux/store/user/get-by-id/get-by-id.action";
import DeleteDialog from "../../../modules/modal/dialog-delete/dialog-delete.view";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import { getLookupEvaluateRecordsGroupService } from "../../../redux/store/user/evaluateRecords/get-lookup-evaluate-records-group/get-lookup-evaluate-records-group.action";
import { districtWithArrayProvinceService } from "../../../redux/store/district/district-multi-province.action";

// const evaluateRecordsId = -1;
const initialState = {
  activeStep: 0,
  provinceId: 0,
  districtId: 0,
  communeId: 0,
  provinceObj: { id: 0, name: "" },
  districtObj: { id: 0, name: "" },
  communeObj: { id: 0, name: "" },
  disableProvince: false,
  disableDistrict: true,
  disableCommune: true,
  groupId: 0,
  groupObj: { id: 0, name: "" },

  name: "",
  taxCode: "",
  address: "",
  phoneNumber: "",
  faxNumber: "",
  emailCompany: "",

  nameDisable: false,
  taxCodeDisable: false,
  addressDisable: false,
  phoneNumberDisable: false,
  faxNumberDisable: false,
  emailCompanyDisable: false,
  accept: false,
  openDialog: false,
  projectName: "",
  projectId: 1,
  location: "",
  provinceIdProject: [],
  districtIdProject: [],
  provinceIdProjectObj: [],
  districtIdProjectObj: [],
  disableProvinceProject: false,
  disableDistrictProject: true,
  acreage: "",
  totalInvestment: "",
  // feesRate: "",
  // fees: "",
  // fileNames: [],
  code: "",
  // disableButtons: [],
  fileUploaded: [],
  isOpenModalDelete: false,
  recordsElementIdDelete: 0,
};
class PlanningDesign extends React.Component {
  steps = this.getSteps();
  currentUser = "";
  constructor(props) {
    super(props);
    this.state = initialState;
    // const [activeStep, setActiveStep] = React.useState(0);
    this.handleNext = this.handleNext.bind(this);
    // this.handleBack = this.handleBack.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlurTaxcode = this.handleBlurTaxcode.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.evaluateRecordsId = this.props.match.params.id;

    this.fileId = this.props.match.params.fileId;
  }

  inputRefs = [];
  deleteRefs = [];
  fileUploadedLocal = [];

  componentDidMount() {
    if (this.fileId) {
      this.props.getById(this.fileId).then((x) => {
        if (this.props.getByIdState.content) {
          var provinceProjectObj = [];
          var districtProjectObj = [];
          for (
            var i = 0;
            i < this.props.getByIdState.content.recordsProvinces.length;
            i++
          ) {
            provinceProjectObj.push({
              id: this.props.getByIdState.content.recordsProvinces[i]
                .provinceId,
              name: this.props.getByIdState.content.recordsProvinces[i]
                .provinceName,
            });
          }
          for (
            var i = 0;
            i < this.props.getByIdState.content.recordsDistricts.length;
            i++
          ) {
            districtProjectObj.push({
              id: this.props.getByIdState.content.recordsDistricts[i]
                .districtId,
              name: this.props.getByIdState.content.recordsDistricts[i]
                .districtName,
            });
          }

          this.setState({
            provinceId: this.props.getByIdState.content.company.provinceId,
            districtId: this.props.getByIdState.content.company.districtId,
            communeId: this.props.getByIdState.content.company.communeId,
            provinceObj: {
              id: this.props.getByIdState.content.company.provinceId,
              name: this.props.getByIdState.content.company.provinceName,
            },
            districtObj: {
              id: this.props.getByIdState.content.company.districtId,
              name: this.props.getByIdState.content.company.districtName,
            },
            communeObj: {
              id: this.props.getByIdState.content.company.communeId,
              name: this.props.getByIdState.content.company.communeName,
            },
            disableProvince: true,
            disableDistrict: true,
            disableCommune: true,

            name: this.props.getByIdState.content.company.name,
            taxCode: this.props.getByIdState.content.company.taxCode,
            address: this.props.getByIdState.content.company.address,
            phoneNumber: this.props.getByIdState.content.company.phoneNumber,
            faxNumber: this.props.getByIdState.content.company.faxNumber,
            emailCompany: this.props.getByIdState.content.company.email,

            nameDisable: true,
            taxCodeDisable: true,
            addressDisable: true,
            phoneNumberDisable: true,
            faxNumberDisable: true,
            emailCompanyDisable: true,

            accept: false,

            projectName: this.props.getByIdState.content.name,
            projectId: this.props.getByIdState.content.projectId,
            location: this.props.getByIdState.content.address,
            provinceIdProjectObj: provinceProjectObj,
            districtIdProjectObj: districtProjectObj,
            disableProvinceProject: false,
            disableDistrictProject: false,
            // acreage: this.props.getByIdState.content.acreage,
            totalInvestment: this.props.getByIdState.content.totalInvestment,
            groupId: this.props.getByIdState.content.evaluateRecordsGroupId,
            groupObj: {
              id: this.props.getByIdState.content.evaluateRecordsGroupId,
              name: this.props.getByIdState.content.evaluateRecordsGroupName,
            },
            // feesRate: this.props.getByIdState.content.feesRate,
            // fees: this.props.getByIdState.content.fees,

            code: this.props.getByIdState.content.code,
            // fileNames: [],
            // disableButtons: [],
          });

          // var files = [];
          this.props.getByIdState.content.listFile.map((obj) => {
            this.fileUploadedLocal.push({
              recordId: this.fileId,
              recordsElementId: obj.recordsElementId,
              fileName: obj.file.fileName,
              fileId: obj.file.fileId,
              disableDelete: false,
              disableSelectFile: true,
            });
          });
          this.setState({
            fileUploaded: this.fileUploadedLocal,
          });
        } else {
          // ShowNotification(
          //   "Hồ sơ không tồn tại",
          //   NotificationMessageType.Error
          // );
          history.push(UrlCollect.Page404);
        }
      });
    }
    this.props.getDetail(this.evaluateRecordsId).then((x) => {
      if (this.props.evaluateRecordsType.content) {
        this.props.evaluateRecordsType.content.evaluateRecordsTypeElements.map(
          (obj, index) => {
            this.inputRefs.push(React.createRef());
            this.deleteRefs.push(React.createRef());
            if (!this.fileId) {
              this.fileUploadedLocal.push({
                recordId: 0,
                recordsElementId: obj.recordsElementId,
                fileName: "",
                fileId: 0,
                disableDelete: true,
                disableSelectFile: false,
              });
            }
          }
        );
        if (!this.fileId) {
          this.setState({
            fileUploaded: this.fileUploadedLocal,
          });
        }
      } else {
        // ShowNotification(
        //   "Chưa có chức năng thẩm duyệt này",
        //   NotificationMessageType.Error
        // );
        history.push(UrlCollect.Page404);
      }
    });

    this.props.getUserDetail();
    this.props.getProvince();
    this.props.getProject(this.evaluateRecordsId);
    this.props.getLookupGroup(this.evaluateRecordsId);
    // this.props.GetUserAccountDetail();
  }
  handleFileUpload(e) {
    e.preventDefault();
    const { files } = e.target;

    if (files && files.length) {
      var id;
      if (this.fileId) {
        id = this.props.getByIdState.content.id;
      } else {
        id = this.props.createStep1.content.id;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("EvaluateRecordsId", id);
      bodyFormData.append("RecordsElementId", e.target.title);
      bodyFormData.append("file", files[0]);
      this.props.uploadFile(bodyFormData).then(() => {
        if (this.props.evaluateRecordUploadFile.content) {
          var isExisted = true;
          if (this.fileUploadedLocal) {
            this.fileUploadedLocal.map((x) => {
              if (
                x.recordsElementId ===
                this.props.evaluateRecordUploadFile.content.recordsElementId
              ) {
                isExisted = false;
                x.fileName = files[0].name;
                x.fileId = this.props.evaluateRecordUploadFile.content.fileId;
                x.disableDelete = false;
                x.disableSelectFile = true;
              }
            });
            if (isExisted) {
              this.fileUploadedLocal.push({
                recordId: id,
                recordsElementId: this.props.evaluateRecordUploadFile.content
                  .recordsElementId,
                fileName: files[0].name,
                fileId: this.props.evaluateRecordUploadFile.content.fileId,
                disableDelete: false,
                disableSelectFile: true,
              });
            }
          }
          this.setState({
            fileUploaded: this.fileUploadedLocal,
          });
        }
      });
    }
  }

  // handleFileName(e) {
  //   alert(e.target.value);
  // }

  selectFileClick(inputFile) {
    inputFile.current.click();
  }

  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }

  onChangeNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value,
      });
    }
  };

  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleOk = () => {
    this.setState({ openDialog: false });
    this.setState({ activeStep: this.state.activeStep - 1 });
    this.setState({ accept: false });
  };

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
    //  setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // handleBack = () => {
  //   // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  handleReset = () => {
    history.push(UrlCollect.HomeUser);
    // this.setState({ activeStep: 0 });
    // //Clear form
    // if (this.fileId) {
    //   this.setState({
    //     accept: false,
    //   });
    // } else {
    //   this.setState(initialState);
    //   this.fileUploadedLocal = [];
    // }

    // setActiveStep(0);
  };

  // Wait for api delete file

  deleteFile = (e) => {
    e.preventDefault();
    var fileDelete = this.fileUploadedLocal.find(
      (x) =>
        x.recordsElementId == this.state.recordsElementIdDelete && x.fileId > 0
    );

    if (fileDelete) {
      var param = fileDelete.recordId + "/" + fileDelete.fileId;

      this.props.deleteFile(param).then(() => {
        if (this.props.deleteFileState.content) {
          this.fileUploadedLocal.map((f) => {
            if (f.recordsElementId == this.state.recordsElementIdDelete) {
              f.fileName = "";
              f.fileId = 0;
              f.disableDelete = true;
              f.disableSelectFile = false;
            }
          });
          this.setState({
            fileUploaded: this.fileUploadedLocal,
            recordsElementIdDelete: 0,
            isOpenModalDelete: false,
          });
        }
      });
    }
  };

  validateUpFile() {
    let isValid = [];
    var item;
    this.props.evaluateRecordsType.content.evaluateRecordsTypeElements.map(
      (obj, index) => {
        if (obj.required) {
          item = document.getElementById("FileName" + index);
          if (item.value === "") {
            isValid.push(index);
          }
        }
      }
    );
    if (isValid.length > 0) {
      item = document.getElementById("FileName" + isValid[0]);
      item.focus();
      ShowNotification(
        "File là này là bắt buộc",
        NotificationMessageType.Error
      );
      return false;
    }

    return true;
  }

  validateStep1() {
    var item;
    if (this.state.taxCode === "") {
      item = document.getElementById("taxCode");
      item.focus();
      ShowNotification(
        "Mã số thuế không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.name === "") {
      item = document.getElementById("name");
      item.focus();
      ShowNotification(
        "Công ty không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.phoneNumber === "") {
      item = document.getElementById("phoneNumber");
      item.focus();
      ShowNotification(
        "Số điện thoại không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.emailCompany === "") {
      item = document.getElementById("emailCompany");
      item.focus();
      ShowNotification(
        "Email không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.emailCompany !== "") {
      const re = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
      if (!re.test(this.state.emailCompany)) {
        item = document.getElementById("emailCompany");
        item.focus();
        ShowNotification("Email không hợp lệ", NotificationMessageType.Error);
        return false;
      }
    }
    if (this.state.provinceId === 0) {
      item = document.getElementsByName("provinceId")[0];
      item.focus();
      ShowNotification(
        "Bạn chưa chọn Thành Phố/Tỉnh",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.districtId === 0) {
      item = document.getElementsByName("districtId")[0];
      item.focus();
      ShowNotification(
        "Bạn chưa chọn Quận/Huyện",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.address === "") {
      item = document.getElementById("address");
      item.focus();
      ShowNotification(
        "Địa chỉ không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.projectName === "") {
      item = document.getElementById("projectName");
      item.focus();
      ShowNotification(
        "Tên dự án không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    // if (this.state.acreage === "") {
    //   item = document.getElementById("acreage");
    //   item.focus();
    //   ShowNotification(
    //     "Diện tích không được để trống",
    //     NotificationMessageType.Error
    //   );
    //   return false;
    // }
    if (this.state.totalInvestment === "") {
      item = document.getElementById("totalInvestment");
      item.focus();
      ShowNotification(
        "Tổng mức đầu tư không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.groupId === 0) {
      item = document.getElementsByName("groupId")[0];
      item.focus();
      ShowNotification("Bạn chưa chọn Nhóm", NotificationMessageType.Error);
      return false;
    }
    if (this.state.provinceIdProjectObj.length === 0) {
      item = document.getElementsByName("provinceIdProject")[0];
      item.focus();
      ShowNotification(
        "Bạn chưa chọn Thành Phố/Tỉnh",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.districtIdProjectObj.length === 0) {
      item = document.getElementsByName("districtIdProject")[0];
      item.focus();
      ShowNotification(
        "Bạn chưa chọn Quận/Huyện",
        NotificationMessageType.Error
      );
      return false;
    }
    if (this.state.location === "") {
      item = document.getElementById("location");
      item.focus();
      ShowNotification(
        "Đia điểm không được để trống",
        NotificationMessageType.Error
      );
      return false;
    }
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.activeStep === 1) {
      if (this.validateUpFile()) {
        if (this.fileId) {
          // Update
          var params = "id=" + this.fileId;
          this.props.api_finishCreate(params).then((x) => {
            if (this.props.finishCreate.content) {
              this.setState({ code: this.props.finishCreate.content.code });
              this.setState({ activeStep: this.state.activeStep + 1 });
            }
          });
        } else {
          // Create
          if (this.props.createStep1.content) {
            var param = "id=" + this.props.createStep1.content.id;

            this.props.api_finishCreate(param).then((x) => {
              if (this.props.finishCreate.content) {
                this.setState({ code: this.props.finishCreate.content.code });
                this.setState({ activeStep: this.state.activeStep + 1 });
              }
            });
          }
        }
      }
    } else if (this.state.activeStep === 0) {
      // Update
      if (this.validateStep1()) {
        var paramProvinceIds = [];
        var paramDistrictIds = [];
        for (var i = 0; i < this.state.provinceIdProjectObj.length; i++) {
          paramProvinceIds.push(this.state.provinceIdProjectObj[i].id);
        }
        for (var i = 0; i < this.state.districtIdProjectObj.length; i++) {
          paramDistrictIds.push(this.state.districtIdProjectObj[i].id);
        }

        if (this.fileId) {
          if (this.props.getByIdState.content) {
            //Fill data for list file uploaded
            var param = {
              id: this.fileId,
              name: this.state.projectName,
              projectId: this.state.projectId,
              address: this.state.location,
              provinceIds: paramProvinceIds,
              districtIds: paramDistrictIds,
              acreage: 0,
              totalInvestment: this.state.totalInvestment,
              evaluateRecordsGroupId: this.state.groupId,
              // feesRate: this.state.feesRate,
              // fees: this.state.fees,
            };
            this.props.updateStep1(param).then((c) => {
              if (this.props.updateStep1State.content) {
                this.setState({ activeStep: this.state.activeStep + 1 });
              }
            });
          }
        } else {
          // Create step1
          var params = {};
          if (this.props.companyUser.content) {
            params = {
              companyId: this.props.companyUser.content.id,
              evaluateRecordsTypeId: this.evaluateRecordsId,
              name: this.state.projectName,
              projectId: this.state.projectId,
              address: this.state.location,
              provinceIds: paramProvinceIds,
              districtIds: paramDistrictIds,
              acreage: 0,
              totalInvestment: this.state.totalInvestment,
              evaluateRecordsGroupId: this.state.groupId,
              // feesRate: this.state.feesRate,
              // fees: this.state.fees,
              company: null,
            };
          } else {
            params = {
              companyId: 0,
              evaluateRecordsTypeId: this.evaluateRecordsId,
              name: this.state.projectName,
              projectId: this.state.projectId,
              address: this.state.location,
              provinceIds: paramProvinceIds,
              districtIds: paramDistrictIds,
              acreage: 0,
              totalInvestment: this.state.totalInvestment,
              evaluateRecordsGroupId: this.state.groupId,
              // feesRate: this.state.feesRate,
              // fees: this.state.fees,
              company: {
                name: this.state.name,
                taxCode: this.state.taxCode,
                address: this.state.address,
                phoneNumber: this.state.phoneNumber,
                faxNumber: this.state.faxNumber,
                email: this.state.emailCompany,
                provinceId: this.state.provinceId,
                districtId: this.state.districtId,
                communeId:
                  this.state.communeId > 0 ? this.state.communeId : null,
              },
            };
          }
          // Create new files
          this.props.createStep1Event(params).then((c) => {
            if (this.props.createStep1.content) {
              this.fileUploadedLocal.map((x) => {
                x.recordId = this.props.createStep1.content.id;
              });
              this.setState({ fileUploaded: this.fileUploadedLocal });
              this.setState({ activeStep: this.state.activeStep + 1 });
            } else {
              // alert("error: " + this.props.createStep1.error);
            }
          });
        }
      }
    } else {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };

  handleBlurTaxcode = () => {
    if (this.state.taxCode !== "") {
      // alert(this.state.taxCode);
      this.props.getCompanyByTaxCode(this.state.taxCode).then((x) => {
        if (this.props.companyUser.content) {
          this.setState({
            name: this.props.companyUser.content.name,
            phoneNumber: this.props.companyUser.content.phoneNumber,
            faxNumber: this.props.companyUser.content.faxNumber,
            emailCompany: this.props.companyUser.content.email,
            address: this.props.companyUser.content.address,

            provinceId: this.props.companyUser.content.provinceId,
            districtId: this.props.companyUser.content.districtId,
            communeId: this.props.companyUser.content.communeId,

            provinceObj: {
              id: this.props.companyUser.content.provinceId,
              name: this.props.companyUser.content.provinceName,
            },
            districtObj: {
              id: this.props.companyUser.content.districtId,
              name: this.props.companyUser.content.districtName,
            },
            communeObj: {
              id: this.props.companyUser.content.communeId,
              name: this.props.companyUser.content.communeName,
            },
            disableProvince: true,
            nameDisable: true,
            phoneNumberDisable: true,
            faxNumberDisable: true,
            emailCompanyDisable: true,
            addressDisable: true,
          });
        } else {
          this.setState({
            disableProvince: false,
            nameDisable: false,
            name: "",
            phoneNumberDisable: false,
            phoneNumber: "",
            faxNumberDisable: false,
            faxNumber: "",
            emailCompanyDisable: false,
            emailCompany: "",
            addressDisable: false,
            address: "",
            provinceId: 0,
            districtId: 0,
            communeId: 0,
            provinceObj: {
              id: 0,
              name: "",
            },
            districtObj: {
              id: 0,
              name: "",
            },
            communeObj: {
              id: 0,
              name: "",
            },
          });
        }
      });
    }
  };
  getSteps() {
    return ["Nhập thông tin", "Tải lên hồ sơ", "Hoàn thành"];
  }

  // const a = props.getProvince();
  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <div className="investor-information">
            <Form.Label className="group-info">
              Thông tin chủ đầu tư/chủ phương tiện
            </Form.Label>
            <Table responsive="sm">
              <tbody>
                <tr>
                  <td className="clear-border-l td-label td-width-20">
                    <Form.Label>
                      Mã số thuế <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td className="td-width-30">
                    <Form.Control
                      id="taxCode"
                      onBlur={this.handleBlurTaxcode}
                      name="taxCode"
                      type="text"
                      value={this.state.taxCode}
                      onChange={this.handleChange}
                      required
                      disabled={this.state.taxCodeDisable}
                    />
                  </td>
                  <td className="td-label td-width-20">
                    <Form.Label>
                      Tên công ty <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td className="td-width-30">
                    <Form.Control
                      id="name"
                      disabled={this.state.nameDisable}
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                      type="text"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td className="clear-border-l td-label">
                    <Form.Label>
                      số điện thoại <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td>
                    <Form.Control
                      id="phoneNumber"
                      disabled={this.state.phoneNumberDisable}
                      name="phoneNumber"
                      value={this.state.phoneNumber}
                      onChange={this.onChangeNumber}
                      type="text"
                      required
                    />
                  </td>
                  <td className="td-label">
                    <Form.Label>số fax</Form.Label>
                  </td>
                  <td>
                    <Form.Control
                      disabled={this.state.faxNumberDisable}
                      name="faxNumber"
                      value={this.state.faxNumber}
                      onChange={this.handleChange}
                      type="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="clear-border-l td-label">
                    <Form.Label>
                      Email <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td colSpan="3" className="">
                    <Form.Control
                      id="emailCompany"
                      disabled={this.state.emailCompanyDisable}
                      name="emailCompany"
                      value={this.state.emailCompany}
                      onChange={this.handleChange}
                      type="text"
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td className="clear-border-l td-label">
                    <Form.Label>
                      Thành Phố/Tỉnh <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td className="">
                    <Autocomplete
                      name="provinceObj"
                      value={this.state.provinceObj}
                      disabled={this.state.disableProvince}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState({
                            provinceId: value.id,
                            provinceObj: value,
                            disableDistrict: false,
                            districtId: 0,
                            districtObj: { id: 0, name: "" },
                            communeId: 0,
                            communeObj: { id: 0, name: "" },
                          });
                          this.props.getDistrict(value.id);
                        } else {
                          this.setState({
                            provinceId: 0,
                            provinceObj: { id: 0, name: "" },
                            districtId: 0,
                            districtObj: { id: 0, name: "" },
                            disableDistrict: true,
                            communeId: 0,
                            communeObj: { id: 0, name: "" },
                            disableCommune: true,
                          });
                        }
                      }}
                      options={
                        this.props.province.content
                          ? this.props.province.content
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      style={{}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="provinceId"
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </td>
                  <td className="td-label">
                    <Form.Label>
                      Quận/Huyện <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td className=" ">
                    <Autocomplete
                      name="districtObj"
                      value={this.state.districtObj}
                      disabled={this.state.disableDistrict}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState({
                            districtId: value.id,
                            districtObj: value,
                            disableCommune: false,
                            communeId: 0,
                            communeObj: { id: 0, name: "" },
                          });
                          this.props.getCommune(value.id);
                        } else {
                          this.setState({
                            districtId: 0,
                            districtObj: { id: 0, name: "" },
                            communeId: 0,
                            communeObj: { id: 0, name: "" },
                            disableCommune: true,
                          });
                        }
                      }}
                      options={
                        this.props.district.content
                          ? this.props.district.content
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      style={{}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="districtId"
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="clear-border-l clear-border-b td-label">
                    <Form.Label>Phường/Xã</Form.Label>
                  </td>
                  <td className="clear-border-b">
                    <Autocomplete
                      disabled={this.state.disableCommune}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState({ communeId: value.id });
                        }
                      }}
                      options={
                        this.props.commune.content
                          ? this.props.commune.content
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      style={{}}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </td>
                  <td className=" clear-border-b td-label">
                    <Form.Label>
                      Địa chỉ trụ sở <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td className=" clear-border-b">
                    <Form.Control
                      id="address"
                      disabled={this.state.addressDisable}
                      name="address"
                      value={this.state.address}
                      onChange={this.handleChange}
                      type="text"
                      required
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <Form.Label className="group-info">
              Thông tin người được ủy quyền
            </Form.Label>
            <Table responsive="sm">
              {this.props.getUser.content ? (
                <tbody>
                  <tr>
                    <td className="clear-border-l td-label td-width-20">
                      <Form.Label>Họ và tên</Form.Label>
                    </td>
                    <td className="td-width-30">
                      <Form.Control
                        value={this.props.getUser.content.fullName}
                        type="text"
                        onChange={this.handleChange}
                        className="bg-white"
                        readOnly={true}
                      />
                    </td>
                    <td className="td-label td-width-20">
                      <Form.Label>Số CMND/CCCD</Form.Label>
                    </td>
                    <td className="td-width-30">
                      <Form.Control
                        value={
                          this.props.getUser.content.identityId
                            ? this.props.getUser.content.identityId
                            : ""
                        }
                        onChange={this.handleChange}
                        type="text"
                        className="bg-white"
                        readOnly={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="clear-border-l td-label">
                      <Form.Label>Địa chỉ</Form.Label>
                    </td>
                    <td>
                      <Form.Control
                        value={
                          this.props.getUser.content.address
                            ? this.props.getUser.content.address
                            : ""
                        }
                        onChange={this.handleChange}
                        type="text"
                        className="bg-white"
                        readOnly={true}
                      />
                    </td>
                    <td className="td-label">
                      <Form.Label>Ngày cấp</Form.Label>
                    </td>
                    <td>
                      <Form.Control
                        value={
                          this.props.getUser.content.identityDate
                            ? DateFormat(
                                this.props.getUser.content.identityDate,
                                "dd/mm/yyyy"
                              )
                            : ""
                        }
                        onChange={this.handleChange}
                        type="text"
                        className="bg-white"
                        readOnly={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="clear-border-l td-label">
                      <Form.Label>Số điện thoại</Form.Label>
                    </td>
                    <td>
                      <Form.Control
                        value={
                          this.props.getUser.content.phoneNumber
                            ? this.props.getUser.content.phoneNumber
                            : ""
                        }
                        onChange={this.handleChange}
                        type="text"
                        className="bg-white"
                        readOnly={true}
                      />
                    </td>
                    <td className="td-label">
                      <Form.Label>Nơi cấp</Form.Label>
                    </td>
                    <td>
                      <Form.Control
                        value={
                          this.props.getUser.content.identityBy
                            ? this.props.getUser.content.identityBy
                            : ""
                        }
                        onChange={this.handleChange}
                        type="text"
                        className="bg-white"
                        readOnly={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="clear-border-l clear-border-b td-label">
                      <Form.Label>Email</Form.Label>
                    </td>
                    <td className=" clear-border-b">
                      <Form.Control
                        value={
                          this.props.getUser.content.email
                            ? this.props.getUser.content.email
                            : ""
                        }
                        onChange={this.handleChange}
                        type="text"
                        className="bg-white"
                        readOnly={true}
                      />
                    </td>
                    <td className=" clear-border-b td-label">
                      <Form.Label>Quốc tịch</Form.Label>
                    </td>
                    <td className=" clear-border-b">
                      <Form.Control
                        className="bg-white"
                        readOnly={true}
                        type="text"
                      />
                    </td>
                  </tr>
                </tbody>
              ) : null}
            </Table>
            <Form.Label className="group-info">
              Thông tin dự án/công trình/phương tiện{" "}
            </Form.Label>
            <Table responsive="sm">
              <tbody>
                <tr>
                  <td className="clear-border-l td-label td-width-20">
                    <Form.Label>
                      Tên dự án/công trình/phương tiện{" "}
                      <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td className="td-width-30">
                    <Form.Control
                      id="projectName"
                      required
                      name="projectName"
                      value={this.state.projectName}
                      onChange={this.handleChange}
                      type="text"
                    />
                  </td>
                  <td className="td-label td-width-20">
                    <Form.Label>
                      {this.evaluateRecordsId == 4
                        ? "Danh mục loại phương tiện "
                        : "Danh mục loại dự án "}
                      <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td className="td-width-30">
                    <Form.Control
                      required
                      name="projectId"
                      as="select"
                      value={this.state.projectId}
                      onChange={this.handleChange}
                    >
                      {this.props.project.content
                        ? this.props.project.content.map((obj) => (
                            <option key={obj.id} value={obj.id}>
                              {obj.name}
                            </option>
                          ))
                        : null}
                    </Form.Control>
                  </td>
                </tr>
                <tr>
                  <td className=" clear-border-l td-label">
                    <Form.Label>
                      Tổng mức đầu tư <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td>
                    <Form.Control
                      id="totalInvestment"
                      required
                      name="totalInvestment"
                      value={this.state.totalInvestment}
                      onChange={this.onChangeNumber}
                      type="text"
                    />
                  </td>
                  <td className="td-label">
                    <Form.Label>
                      Nhóm <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td className="">
                    {/* <Form.Control
                      id="acreage"
                      required
                      name="acreage"
                      value={this.state.acreage}
                      onChange={this.onChangeNumber}
                      type="text"
                    /> */}
                    <Autocomplete
                      name="groupObj"
                      value={this.state.groupObj}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState({
                            groupId: value.id,
                            groupObj: value,
                          });
                        } else {
                          this.setState({
                            groupId: 0,
                            groupObj: { id: 0, name: "" },
                          });
                        }
                      }}
                      options={
                        this.props.getLookupGroupState.content
                          ? this.props.getLookupGroupState.content
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      style={{}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="groupId"
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="clear-border-l td-label">
                    <Form.Label>
                      Thành Phố/Tỉnh <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td className="">
                    <Autocomplete
                      multiple
                      limitTags={1}
                      name="provinceIdProjectObj"
                      value={this.state.provinceIdProjectObj}
                      disabled={this.state.disableProvinceProject}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState({ provinceIdProjectObj: value });
                          this.setState({
                            districtIdProjectObj: [],
                            disableDistrictProject: false,
                          });
                          this.props.districtWithArrayProvince(value);
                        } else {
                          this.setState({
                            provinceIdProjectObj: [],
                            districtIdProjectObj: [],
                            disableDistrictProject: true,
                          });
                        }
                      }}
                      options={
                        this.props.province.content
                          ? this.props.province.content
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      style={{}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="provinceIdProject"
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </td>
                  <td className="td-label">
                    <Form.Label>
                      Quận/Huyện <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td className=" ">
                    <Autocomplete
                      multiple
                      limitTags={1}
                      name="districtIdProjectObj"
                      value={this.state.districtIdProjectObj}
                      disabled={this.state.disableDistrictProject}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState({ districtIdProjectObj: value });
                        } else {
                          this.setState({
                            districtIdProjectObj: [],
                          });
                        }
                      }}
                      options={
                        this.props.districtWithArrayProvinceState.content
                          ? this.props.districtWithArrayProvinceState.content
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      style={{}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="districtIdProject"
                          required
                          variant="outlined"
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td className=" clear-border-l clear-border-b td-label">
                    <Form.Label>
                      Địa điểm xây dựng/chế tạo/hoán cải{" "}
                      <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td colSpan="3" className=" clear-border-b">
                    <Form.Control
                      id="location"
                      required
                      name="location"
                      value={this.state.location}
                      onChange={this.handleChange}
                      type="text"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            {/* <Form.Label className="group-info">
              Lệ phí thẩm duyệt (VND)
            </Form.Label>
            <Table responsive="sm">
              <tbody>
                <tr>
                  <td className="td-label">
                    <Form.Label>
                      Mức thu <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td>
                    <Form.Control
                      required
                      name="feesRate"
                      value={this.state.feesRate}
                      onChange={this.handleChange}
                      type="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="clear-border-l clear-border-b td-label">
                    <Form.Label>
                      Lệ phí phải nộp <span className="requite">*</span>
                    </Form.Label>
                  </td>
                  <td colSpan="3" className="clear-border-b">
                    <Form.Control
                      required
                      name="fees"
                      value={this.state.fees}
                      onChange={this.handleChange}
                      type="text"
                    />
                  </td>
                </tr>
              </tbody>
            </Table> */}

            {/* <Form.Label className="group-info">Hiển thị chữ ký</Form.Label>
              <Table className="tb-chu-ky" responsive="sm">
                <tbody>
                  <tr>
                    <td colSpan="3" className="clear-border-l g-r-chu-ky">
                      <Form.Check
                        inline
                        label="Hình ảnh và thông tin"
                        type="radio"
                      />
                      <Form.Check inline label="Hình ảnh" type="radio" />
                      <Form.Check inline label="Thông tin" type="radio" />
                    </td>

                    <td>
                      <Form.Check inline label="Chữ ký tổ chức" type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="3"
                      rowSpan="6"
                      className="clear-border-l g-i-chu-ky"
                    >
                      <Image
                        className="i-chu-ky"
                        src={require("../../../assets/images/image 2.png")}
                      />
                    </td>
                    <td>
                      <Form.Check inline label="Nhãn" type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Check inline label="Email" type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Check inline label="Cơ quan" type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Check inline label="Đơn vị" type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Check inline label="Chức vụ" type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Check inline label="Thời gian ký" type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td className="clear-border-l">
                      <Form.Check
                        inline
                        label="Mẫu chữ ký mặc định"
                        type="checkbox"
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table> */}
            <Form.Check
              className="accept"
              name="accept"
              label="Tôi xin chịu trách nhiệm trước pháp luật về những thông tin đã khai ở trên"
              type="checkbox"
              checked={this.state.accept}
              onChange={(e) => {
                this.setState({ accept: e.target.checked });
              }}
            />
          </div>
        );
      case 1:
        const getFileName = (recordElementId) => {
          if (this.state.fileUploaded) {
            var b = this.state.fileUploaded.find(
              (x) => x.recordsElementId === recordElementId
            );
            if (b) {
              return b.fileName;
            } else {
              return "";
            }
          }
        };

        const disableDelete = (recordElementId) => {
          if (this.state.fileUploaded) {
            var b = this.state.fileUploaded.find(
              (x) => x.recordsElementId === recordElementId
            );
            if (b) {
              return b.disableDelete;
            } else {
              return true;
            }
          }
        };

        const disableSelectFile = (recordElementId) => {
          if (this.state.fileUploaded) {
            var b = this.state.fileUploaded.find(
              (x) => x.recordsElementId === recordElementId
            );
            if (b) {
              return b.disableSelectFile;
            } else {
              return false;
            }
          }
        };
        return (
          <div className="investor-information upload-file">
            {/* ho so tham duyet */}

            <Form.Label className="group-info">Hồ sơ thẩm duyệt </Form.Label>
            <div className="note">
              <b>Chú ý: </b>
              <span className="requite">
                Chỉ chấp nhận các loại file png, jpg, jpeg, gif, pdf, dwg, rar,
                zip
              </span>
            </div>

            <Table responsive="sm">
              <tbody>
                {this.props.evaluateRecordsType.content
                  ? this.props.evaluateRecordsType.content.evaluateRecordsTypeElements.map(
                      (obj, index) => (
                        <tr key={index}>
                          <td className=" td-label">
                            <Form.Label>{obj.recordsElementId}</Form.Label>
                          </td>
                          <td className="td-label">
                            <Form.Label>
                              {obj.recordsElementName}
                              {obj.required ? (
                                <span className="requite"> *</span>
                              ) : (
                                ""
                              )}
                            </Form.Label>
                          </td>
                          <td>
                            {obj.required ? (
                              <Form.Control
                                id={"FileName" + index}
                                key={"FileName" + obj.recordsElementId}
                                value={getFileName(obj.recordsElementId)}
                                required
                                className="bg-white"
                                // readOnly={true}
                                type="text"
                              />
                            ) : (
                              <Form.Control
                                id={"FileName" + index}
                                key={"FileName" + obj.recordsElementId}
                                // ref={this.inputRefs[index]}
                                value={getFileName(obj.recordsElementId)}
                                // onChange={(e) =>
                                //   this.handleFileName(this.inputRefs[index])
                                // }
                                // readOnly={true}
                                className="bg-white"
                                type="text"
                              />
                            )}
                          </td>
                          <td className="action">
                            <input
                              key={"file" + obj.recordsElementId}
                              title={obj.recordsElementId}
                              ref={this.inputRefs[index]}
                              onChange={(e) => this.handleFileUpload(e)}
                              className="display-none"
                              type="file"
                              accept=".png, .jpg, .jpeg, .gif, .pdf, .dwg, .rar, .zip"
                            />
                            <Button
                              ref={this.deleteRefs[index]}
                              disabled={disableDelete(obj.recordsElementId)}
                              id={"button-delete" + obj.recordsElementId}
                              key={"button-delete" + obj.recordsElementId}
                              title={obj.recordsElementId}
                              onClick={(e) =>
                                this.setState({
                                  isOpenModalDelete: true,
                                  recordsElementIdDelete: e.target.title,
                                })
                              }
                              className="button-back mr-sm-2"
                            >
                              Xóa file
                            </Button>
                            <Button
                              disabled={disableSelectFile(obj.recordsElementId)}
                              key={"button-select-file" + obj.recordsElementId}
                              id={"button-select-file" + obj.recordsElementId}
                              onClick={(e) =>
                                this.selectFileClick(this.inputRefs[index])
                              }
                              className="button-file"
                            >
                              Chọn file
                            </Button>
                          </td>
                        </tr>
                      )
                    )
                  : null}
              </tbody>
            </Table>
            <hr />
          </div>
        );
      case 2:
        return (
          <div className="investor-information center confirm-code">
            <p>
              Mã hồ sơ là: <span className="code">{this.state.code}</span>{" "}
              <br />
              Hồ sơ đã được gửi hoàn tất và chờ tiếp nhận. Hãy theo dõi email để
              nhận thông báo
            </p>
          </div>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  render() {
    return (
      <section className="appraisal-and-approval">
        <Container>
          <Breadcrumb className="title">
            <Breadcrumb.Item
              href={UrlCollect.HomeUser}
              className="breadcrumb-icon-home"
            >
              <img src={require("../../../assets/icon/entypo_home.png")}></img>{" "}
              Trang chủ
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {this.props.evaluateRecordsType.content
                ? this.props.evaluateRecordsType.content.name
                : ""}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="body-content">
            {/* step=================== */}
            <Stepper activeStep={this.state.activeStep} alternativeLabel>
              {this.steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              <Form noValidate onSubmit={this.handleSubmit}>
                {this.state.activeStep === this.steps.length ? (
                  <div className="action">
                    <Typography component={"div"} variant={"body2"}>
                      <div className="center confirm-success">
                        {this.fileId ? (
                          <p>Chỉnh sửa hồ sơ thành công</p>
                        ) : (
                          <p>Nộp hồ sơ thành công</p>
                        )}
                      </div>
                    </Typography>
                    <Button className="button-gui" onClick={this.handleReset}>
                      Kết thúc
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Typography component={"div"} variant={"body2"}>
                      {this.getStepContent(this.state.activeStep)}
                    </Typography>
                    <div className="action">
                      {/* <Button
                    className="button-xoa"
                    disabled={this.state.activeStep === 0}
                    onClick={this.handleClickOpen}
                  >
                    Trở lại
                  </Button> */}
                      <Button
                        type="submit"
                        className="button-gui"
                        disabled={!this.state.accept}
                        // onClick={this.handleNext}
                      >
                        {this.state.activeStep === this.steps.length - 1
                          ? "Hoàn thành"
                          : "Tiếp theo"}
                      </Button>
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </Container>
        <DeleteDialog
          isOpen={this.state.isOpenModalDelete}
          onClose={() => this.setState({ isOpenModalDelete: false })}
          onSuccess={this.deleteFile}
          header="Xóa file"
          content="Bạn có chắc chắn muốn xóa file này không?"
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  province: state.provinceUser,
  district: state.district,
  commune: state.commune,
  project: state.projectUser,
  user: state.user,
  getUser: state.getUser,
  companyUser: state.companyUser,
  createStep1: state.createStep1,
  evaluateRecordsType: state.evaluateRecordsType,
  evaluateRecordUploadFile: state.evaluateRecordUploadFile,
  finishCreate: state.finishCreate,
  getByIdState: state.getByIdUser,
  updateStep1State: state.updateStep1,
  deleteFileState: state.evaluateRecordDeleteFile,
  getLookupGroupState: state.getLookupEvaluateRecordsGroup,
  districtWithArrayProvinceState: state.districtWithArrayProvince,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProvince: provinceService.getProvince,
      getDistrict: districtService.getDistrict,
      districtWithArrayProvince:
        districtWithArrayProvinceService.getLookupDistrictWithArrayProvince,
      getCommune: communeService.getCommune,
      getProject: projectService.getProject,
      getCompanyByTaxCode: companyService.getCompanyByTaxCode,
      // GetUserAccountDetail: userService.GetUserAccountDetail,
      getUserDetail: getUserService.getUserDetail,
      createStep1Event: createStep1Service.createStep1,
      getDetail: evaluateRecordsTypeService.getDetail,
      uploadFile: uploadFileService.uploadFile,
      api_finishCreate: finishCreateService.finishCreate,
      getById: getByIdService.getById,
      deleteFile: deleteFileService.deleteFile,
      updateStep1: updateStep1Service.updateStep1,
      getLookupGroup:
        getLookupEvaluateRecordsGroupService.getLookupEvaluateRecordsGroup,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(PlanningDesign);
